<template>
  <div>
    <ul class="text-data-list" v-if="textData.length">
      <li v-for="(item, index) in textData" :key="index">{{ item.value }}</li>
    </ul>
    <div class="show-more" v-if="data[0].textData.length > showCount">
      <button class="button button--outline" @click="showAll">
        {{ !this.isShowAll ? $t("common.show_more") : $t("common.show_less") }}
        {{ !this.isShowAll ? `(${data[0].textData.length - showCount})` : "" }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  name: "textdata",
  props: {
    data: Array,
  },
  data() {
    return {
      isShowAll: false,
      showCount: 10,
      textData: [],
    };
  },
  created() {
    this.setTextData();
  },
  methods: {
    setTextData() {
      !this.isShowAll
        ? (this.textData = this.data[0].textData.slice(0, this.showCount))
        : (this.textData = this.data[0].textData);
    },
    showAll() {
      this.isShowAll = !this.isShowAll;
      this.setTextData();
    },
  },
};
</script>
<style lang="scss" scoped>
.show-more {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 0;
}
ul.text-data-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 0.5em 1rem;

  li {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0.85em 0;
  }
}
</style>
