<template>
  <Card
    v-if="data.length"
    :title="data[0].title"
    :full="full ? true : false"
    style="min-height: 317px"
  >
    <Loading v-if="isLoading" />
    <pie-chart
      v-if="!isLoading && chartType === 'pie'"
      :data="data[0].data"
      :height="250"
      :legend="legend"
    />
    <percentage-bar-chart
      v-if="!isLoading && chartType === 'bar'"
      :data="data[0].data"
      :height="250"
    />
    <HorizontalBarChart
      v-if="!isLoading && chartType === 'bar-horizontal'"
      :data="data"
    />
    <TextData
      v-if="!isLoading && chartType === 'text'"
      :data="data"
      :height="250"
    />
    <ul
      class="data-flex"
      v-if="data.additionalData && data.additionalData.length"
    >
      <li
        v-for="additionalData in data.additionalData"
        :key="additionalData.label"
      >
        <span>{{ additionalData.label }}:</span>
        {{ roundToTwo(parseFloat(additionalData.value)) }}
      </li>
    </ul>
    <slot></slot>
  </Card>
</template>
<script>
import Card from "../Card.vue";
import { getReportChart } from "@/api/reportController";
import { mapState } from "vuex";
import Loading from "../Loading.vue";
import PieChart from "../charts/PieChart.vue";
import PercentageBarChart from "../charts/PercentageBarChart.vue";
import HorizontalBarChart from "../charts/HorizontalBarChart.vue";
import TextData from "../charts/TextData.vue";

export default {
  name: "ChartCard",
  components: {
    Card,
    Loading,
    PieChart,
    PercentageBarChart,
    HorizontalBarChart,
    TextData,
  },

  props: {
    legend: {
      type: String,
      default: "bottom",
    },
    full: {
      type: Boolean,
      default: false,
    },
    chartType: {
      type: String,
      default: "pie",
    },
    questionType: String,
  },
  data() {
    return {
      data: [],
      isLoading: false,
      error: "",
    };
  },

  computed: {
    ...mapState({
      researchWeek: (state) => state.inquiries.selectedInquiry.researchWeek,
      queryId: (state) => state.inquiries.selectedInquiryId,
      workplaceId: (state) => state.workplaces.selectedWorkplace,
      selectedLocale: (state) => state.selectedLocale,
      companyId: (state) => state.companies.selectedCompanyId,
      selectedCommuterType: (state) => state.reports.selectedCommuterType,
    }),
  },

  methods: {
    roundToTwo(num) {
      return +(Math.round(num + "e+2") + "e-2");
    },
    async getData() {
      if (
        !this.queryId ||
        !this.questionType ||
        !this.researchWeek ||
        !this.selectedLocale
      )
        return;
      try {
        this.isLoading = true;
        const response = await getReportChart(
          {
            query_id: this.queryId,
            question_type: this.questionType,
            research_week: this.researchWeek,
            language: this.selectedLocale,
            ...(this.workplaceId ? { workplace_id: this.workplaceId } : {}),
            ...(this.selectedCommuterType
              ? { commuter_type: this.selectedCommuterType }
              : {}),
          },
          this.selectedLocale
        );
        this.data = response.data;
      } catch (err) {
        console.log(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  watch: {
    workplaceId() {
      this.getData();
    },
    queryId() {
      this.getData();
    },
    companyId() {
      this.getData();
    },
    selectedLocale() {
      this.getData();
    },
    selectedCommuterType() {
      this.getData();
    },
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
ul.data-flex {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 0;
  padding: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
