<script>
import { Pie } from "vue-chartjs";
export default {
  name: "pie-chart",
  props: {
    data: {
      type: Array,
    },
    legend: {
      type: String,
      default: "bottom",
    },
  },
  extends: Pie,
  data: function () {
    return {
      dataset: [],
      labels: [],
      options: {
        type: "pie",
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
          position: this.legend,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItems, data) {
              const label = data.labels[tooltipItems.index] || "";
              const value =
                data.datasets[tooltipItems.datasetIndex].data[
                  tooltipItems.index
                ] || null;
              return `${label}: ${value} %`;
            },
          },
        },
      },
    };
  },
  mounted() {
    if (this.data) {
      this.generateChartData();
    }
    this.renderChart(this.getChartData(), this.options);
  },
  watch: {
    data() {
      this.renderChart(this.getChartData(), this.options);
    },
  },
  methods: {
    generateChartData() {
      this.dataset = [];
      this.labels = [];
      let colors = [];
      // Generate labels and color array
      this.data.forEach((item) => {
        colors.push(item.color);
        this.labels.push(item.label);
      });
      // Generate a chart data set for each
      let data = [];
      this.data.forEach((item) => {
        data.push(+(Math.round(item.value + "e+2") + "e-2"));
      });
      this.dataset.push({
        data: data,
        borderColor: "rgba(232,232,232,1)",
        borderWidth: 1,
        backgroundColor: colors,
      });
    },
    getChartData: function () {
      return {
        datasets: this.dataset,
        labels: this.labels,
      };
    },
    getChartAsImage: function () {
      return this.$data._chart.toBase64Image();
    },
  },
};
</script>
