<template lang="">
  <div class="dash-card" :class="!full ? '' : 'full'">
    <h5 class="title" v-if="title">{{ title }}</h5>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    slotted: Boolean,
    full: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" scoped>
.dash-card {
  border-radius: 5px;
  padding: 1.5rem 1rem;
  background-color: #fff;
  &.full {
    grid-column: 1 / -1;
  }

  .title {
    font-weight: 500;
    display: block;
    margin-bottom: 3rem;
    font-size: 1rem;
  }
}
</style>
