<script>
import { Bar } from "vue-chartjs";
export default {
  name: "percentage-bar-chart",
  props: {
    data: {
      type: Array,
    },
  },
  extends: Bar,
  data: function () {
    return {
      dataset: [],
      labels: [],
      options: {
        type: "bar",
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
          position: "bottom",
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItems, data) {
              var label = data.datasets[tooltipItems.datasetIndex].label || "";
              return `${label}: ${
                tooltipItems.value > 1 ? tooltipItems.value : "0"
              } %`;
            },
          },
        },
        scales: {
          xAxes: [
            {
              borderRadius: 10,
              ticks: {
                padding: 10,
              },
              // stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                lineWidth: 1,
                drawTicks: false,
                drawOnChartArea: false,
              },
            },
          ],
          yAxes: [
            {
              // stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                lineWidth: 1,
                drawTicks: false,
                drawOnChartArea: false,
              },
              ticks: {
                padding: 10,
                beginAtZero: true,
                stepSize: 25,
                min: 0,
                max: 100,
                callback: function (value) {
                  return `${value} % `;
                },
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    if (this.data) {
      this.generateChartData();
    }
    this.renderChart(this.getChartData(), this.options);
  },
  watch: {
    data() {
      this.renderChart(this.getChartData(), this.options);
    },
  },
  methods: {
    generateChartData() {
      this.dataset = [];
      this.labels = [];
      let colors = [];
      // Generate labels and color array
      this.data.forEach((item) => {
        colors.push(item.color);
        this.labels.push(item.label);
      });
      // Generate a chart data set for each
      let data = [];
      const values = this.data.map((item) => item.value);
      const sum = values.reduce((prev, cur) => prev + cur);
      this.data.forEach((item) => {
        data.push(Math.round((item.value / sum) * 100));
      });
      this.dataset.push({
        data: data,
        borderColor: "rgba(232,232,232,1)",
        borderWidth: 0,
        barThickness: 55,
        backgroundColor: colors,
      });
    },
    getChartData: function () {
      return {
        datasets: this.dataset,
        labels: this.labels,
      };
    },
    getChartAsImage: function () {
      return this.$data._chart.toBase64Image();
    },
  },
};
</script>
