<script>
import { HorizontalBar } from "vue-chartjs";

export default {
  name: "HorizontalBarChart",
  props: {
    data: {
      type: Array,
    },
  },
  extends: HorizontalBar,
  data: function () {
    return {
      dataset: [],
      labels: [],
      options: {
        type: "horizontalBar",
        elements: {
          bar: {
            borderWidth: 0,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: true,
        },
        tooltips: {
          callbacks: {
            label: function (tooltipItems, data) {
              var label = data.datasets[tooltipItems.datasetIndex].label || "";
              return `${label}: ${
                tooltipItems.value > 1 ? tooltipItems.value : "0"
              } %`;
            },
          },
        },
        scales: {
          xAxes: [
            {
              stacked: true,
              ticks: {
                padding: 10,
                beginAtZero: true,
                stepSize: 25,
                min: 0,
                max: 100,
                callback: function (value) {
                  return `${value} %`;
                },
              },
              gridLines: {
                display: true,
                drawBorder: true,
                lineWidth: 1,
                drawTicks: false,
                drawOnChartArea: false,
              },
            },
          ],
          yAxes: [
            {
              barThickness: 30,
              ticks: {
                padding: 10,
              },
              stacked: true,
              gridLines: {
                display: true,
                drawBorder: true,
                lineWidth: 1,
                drawOnChartArea: false,
                drawTicks: false,
              },
            },
          ],
        },
      },
    };
  },
  mounted() {
    if (this.data) {
      this.generateChartData();
    }
    this.renderChart(this.getChartData(), this.options);
  },
  watch: {
    data() {
      this.renderChart(this.getChartData(), this.options);
    },
  },
  computed: {
    singleData() {
      return this.data[0].data;
    },
  },
  methods: {
    generateChartData() {
      this.dataset = [];
      this.labels = [];
      if (this.data.length > 1) {
        this.labels = this.data.map((item) => item.subTitle);
        // const sumsArr = this.data.map((item) =>
        //   item.data
        //     .map((val) => +(Math.round(val.value + "e+2") + "e-2"))
        //     .reduce((prev, cur) => prev + cur)
        // );
        // console.log(sumsArr);
        // const sum = [].concat(...sumsArr).reduce((prev, cur) => prev + cur);
        // console.log(sum);

        this.dataset = this.data
          .map((item, index) => {
            if (index + 1 <= item.data.length) {
              return {
                data: this.data.map(
                  (single) =>
                    +(Math.round(single.data[index].value + "e+2") + "e-2")
                ),
                label: item.data[index].label,
                backgroundColor: item.data[index].color,
              };
            }
          })
          .filter((item) => item);
        return;
      }

      // const values = this.singleData.map((item) => item.value);
      // const sum = values.length && values.reduce((prev, cur) => prev + cur);

      this.options.legend.display = false;
      this.options.maintainAspectRatio = false;
      this.labels = this.singleData.map((item) => item.label);
      this.dataset = [
        {
          data: this.singleData.map(
            (item) => +Math.round(item.value + "e+2") + "e-2"
          ),
          backgroundColor: this.singleData.map((item) => item.color),
          barThickness: 30,
          borderWidth: 0,
        },
      ];
    },

    getChartData: function () {
      return {
        datasets: this.dataset,
        labels: this.labels,
      };
    },
    getChartAsImage: function () {
      return this.$data._chart.toBase64Image();
    },
  },
};
</script>
